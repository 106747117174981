export const registrationSiteMap = {
  CreateAccountPage: {
    path: "/registration",
  },
  SetupPasswordPage: {
    path: "/registration/password",
  },
  SetupKYCPage: {
    path: "/registration/kyc",
  },
  NewCreditLimitCalculationPage: {
    path: "/registration/credit-limit-calculation",
  },
  OfferDetailsPage: {
    path: "/registration/offer-details",
  },
  UpdateDateOfBirth: {
    path: "/registration/update-date-of-birth",
  },
};
