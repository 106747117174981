import { E_PROMISSORY_NOTE_STATE } from "@utils/network/contractInfo/types";
import { ICustomerDetailsResponse } from "@utils/network/useCustomerDetails";

import { ORDER_STATUSES } from "./business/orderStatuses";

export type baseStorageItemType<T extends unknown> = T & {
  isDefault: boolean;
};

export const STORAGE_DEFAULT_ORDER: baseStorageItemType<ICustomerDetailsResponse> = {
  isDefault: true,
  expirationDate: undefined,
  totalAmount: {
    amount: 0,
    currency: "",
  },
  quantity: 0,
  items: [],
};

// eslint-disable-next-line
export const STORAGE_DEFAULT_OFFER: baseStorageItemType<{ eligibilityStatus: boolean; mappedOffers: any }> = {
  isDefault: true,
  eligibilityStatus: true,
  mappedOffers: {},
};

export const DEFAULT_USER_INFO = {
  status: "",
  otpGeneratedAt: "",
  arabicFirstName: "",
  englishFirstName: "",
  phoneNumber: "",
};

export const DEFAULT_USER_REGISTRATION_INFO = {
  form: {
    MOBILE_PHONE: "",
    BIRTH_DATE: "",
    NATIONAL_ID: "",
  },
};

export const STORAGE_DEFAULT_CONTRACT = {
  orderToken: "",
  merchantUrl: "",
  timer: "",
  orderId: "",
  isNoteSigned: false,
  status: "" as keyof typeof ORDER_STATUSES | "",
  ePromissoryNoteState: E_PROMISSORY_NOTE_STATE.UNDEFINED,
};

export const BOX_BACKGROUND_COLOR_DEFAULT_CONTRACT = {
  withColor: true,
};

export const DEFAULT_MERCHANT_DATA_KEY = {
  orderId: "",
  checkoutToken: "",
  merchantUrl: "",
  merchantSuccessUrl: "",
  merchantFailureUrl: "",
  merchantCancelUrl: "",
};
