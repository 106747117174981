import { BehaviorSubject, Observable } from "rxjs";

import { AUTHENTICATION_KEY } from "@constants/sessionStorageKeys";

export interface IAuthorization {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
  error?: string;
}

export interface ILogInInfo {
  isLoggedIn: boolean;
  lastRequest: string;
}

const storageData = sessionStorage.getItem(AUTHENTICATION_KEY)
  ? JSON.parse(sessionStorage.getItem(AUTHENTICATION_KEY) || '""')
  : {};

export const initialValue: IAuthorization = {
  access_token: storageData.access_token || "",
  expires_in: storageData.expires_in || 0,
  token_type: storageData.token_type || "",
  scope: storageData.scope || "",
  refresh_token: storageData.refresh_token || "",
};

export const logInfoInitialValue = {
  isLoggedIn: !!storageData.access_token && !!storageData.refresh_token,
  lastRequest: "",
};

const authorization$ = new BehaviorSubject<IAuthorization>(initialValue);
const loggedInStatus$ = new BehaviorSubject<ILogInInfo>(logInfoInitialValue);

export const authorizationStore = {
  getValue: (): IAuthorization => authorization$.value,
  setAuthorization: (authorizationInfo: IAuthorization): void => {
    authorization$.next({
      ...authorization$.getValue(),
      access_token: authorizationInfo.access_token,
      expires_in: authorizationInfo.expires_in,
      token_type: authorizationInfo.token_type,
      scope: authorizationInfo.scope,
      refresh_token: authorizationInfo.refresh_token,
    });
    loggedInStatus$.next({
      ...loggedInStatus$.getValue(),
      isLoggedIn: !!authorizationInfo.access_token && !!authorizationInfo.refresh_token,
    });
  },
  getAuthorization: (): Observable<IAuthorization> => authorization$.asObservable(),
  isLoggedIn: (): boolean => loggedInStatus$.value.isLoggedIn,
  getLoggedInStatus: (): Observable<ILogInInfo> => loggedInStatus$.asObservable(),
  getLoggedInStatusValue: (): ILogInInfo => loggedInStatus$.value,
  setIsLogged: (isLoggedIn: boolean): void => loggedInStatus$.next({ ...loggedInStatus$.getValue(), isLoggedIn }),
  setLastRequest: (request: string): void =>
    loggedInStatus$.next({ ...loggedInStatus$.getValue(), lastRequest: request }),
};
