import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { COLORS, Typography, ScrollableContainer } from "@ui-kit";
import { memo, useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router-dom";

import * as httpCodes from "@constants/httpStatuses";
import { URL_CHECKOUT_TOKEN, URL_ORDER_TOKEN } from "@constants/queryKeys";
import {
  baseStorageItemType,
  STORAGE_DEFAULT_CONTRACT,
  STORAGE_DEFAULT_ORDER,
} from "@constants/sessionStorageDefaults";
import { CONTRACT_DATA_KEY, MERCHANT_DATA_KEY, ORDER_DATA_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import { useQuery } from "@utils/hooks/useQuery/useQuery";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { ICustomerDetailsResponse, useCustomerDetails } from "@utils/network/useCustomerDetails";
import { convertDigitsToArabic } from "@utils/services/Text/Text.service";

import { ImageWrapper } from "./components/ImageWrapper";

export const OrderInfo: React.FC = memo(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const theme = useTheme();
  const [merchantStorageValue] = useSessionStorageState(MERCHANT_DATA_KEY, {
    checkoutToken: "",
    merchantUrl: "",
    merchantOrderId: "",
    merchantSuccessUrl: "",
    merchantFailureUrl: "",
    merchantCancelUrl: "",
  });
  const [storageOrderDetails, setOrderDetailsToStorage] = useSessionStorageState(ORDER_DATA_KEY, STORAGE_DEFAULT_ORDER);
  const [storageOrderStatus, setStorageOrderStatus, clearContractStorage] = useSessionStorageState(
    CONTRACT_DATA_KEY,
    STORAGE_DEFAULT_CONTRACT,
  );
  const checkoutToken = query.get(URL_CHECKOUT_TOKEN) || merchantStorageValue.checkoutToken;
  const orderToken = query.get(URL_ORDER_TOKEN) || storageOrderStatus.orderToken;
  const [customerDetailsRequest] = useCustomerDetails();
  const [orderDetails, setOrderDetails] = useState<baseStorageItemType<ICustomerDetailsResponse>>(storageOrderDetails);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  useEffect(() => {
    if (orderToken) {
      setStorageOrderStatus({ ...storageOrderStatus, orderToken });
    } else {
      clearContractStorage();
    }

    if ((checkoutToken || orderToken) && storageOrderDetails.isDefault) {
      const getOrderDetails = async () => {
        try {
          const { data, status } = await customerDetailsRequest(checkoutToken ? { checkoutToken } : { orderToken });

          switch (status) {
          case httpCodes.OK: {
            setOrderDetails({ ...data, isDefault: false });
            setOrderDetailsToStorage({ ...data, isDefault: false });

            break;
          }
          case httpCodes.FORBIDDEN:
          case httpCodes.NOT_FOUND:
          case httpCodes.INTERNAL_SERVER_ERROR:
            console.log("Error in response status", status);
            history.push(siteMap.Error404Page.path);
          }
        } catch (error) {
          console.log("Error in catch", error);
          history.push(siteMap.Error404Page.path);
        }
      };
      getOrderDetails();
    }
  }, []);

  if (isSmallScreen || orderDetails.isDefault) {
    return null;
  }

  return (
    <Box>
      <Grid container flexDirection="column">
        <Grid item>
          <Typography
            component="p"
            variant="p1"
            fontWeight={theme.direction === "ltr" ? 900 : 600}
            fontSize="2rem"
            margin="0"
          >
            {t("orderContent.header")}
          </Typography>
        </Grid>
        {orderDetails?.totalAmount && orderDetails?.quantity ? (
          <Grid item>
            <Typography
              component="p"
              variant="p2"
              fontSize={theme.direction === "rtl" ? "1.2rem" : "1.4rem"}
              fontWeight="500"
              margin={theme.direction === "rtl" ? "0 0 10px 0" : "0 0 24px 0"}
              paddingTop="20px"
              paddingBottom="24px"
              borderBottom={`1px solid ${COLORS.GREY_08}`}
            >
              <Trans
                i18nKey="orderContent.subheader.keyWithCount"
                values={{
                  sum:
                    theme.direction === "ltr"
                      ? `${orderDetails.totalAmount.currency} ${orderDetails.totalAmount.amount}`
                      : convertDigitsToArabic(orderDetails.totalAmount.amount) +
                        " " +
                        t(`currency.${orderDetails.totalAmount.currency}`),
                  count: orderDetails.quantity,
                  itemsAmount:
                    theme.direction === "ltr" ? orderDetails.quantity : convertDigitsToArabic(orderDetails.quantity),
                }}
                components={[
                  <strong key={0} style={{ fontWeight: theme.direction === "ltr" ? "bold" : 600 }} />,
                  <strong key={1} style={{ fontWeight: theme.direction === "ltr" ? "bold" : 600 }} />,
                ]}
              />
            </Typography>
          </Grid>
        ) : null}
        {orderDetails?.items?.length ? (
          <>
            <Typography
              component="p"
              variant="p3"
              fontWeight={theme.direction === "ltr" ? 900 : 600}
              color={COLORS.GREY_64}
              mb="12px"
            >
              {t("orderContent.productsInCart")}
            </Typography>
            <ScrollableContainer maxHeight={260} item>
              {orderDetails.items.map((item, index) => (
                <Box
                  key={item.id}
                  display="flex"
                  mb="16px"
                  alignItems="center"
                >
                  <ImageWrapper src={item.imageUrl} />
                  <Box ml="12px" flexGrow={1} mr="10px">
                    <Box display="flex" justifyContent="space-between" mb="4px">
                      <Typography component="div" variant="p3" fontWeight="bold">
                        {index + 1}. {item.name}
                      </Typography>
                      <Typography component="div" variant="p3" fontWeight="bold">
                        x{item.quantity}
                      </Typography>
                    </Box>
                    <Typography component="p" variant="p3" color={COLORS.GREY_80}>
                      {item.unitPrice.amount} {item.unitPrice.currency}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </ScrollableContainer>
          </>
        ) : null}
      </Grid>
    </Box>
  );
});

OrderInfo.displayName = "OrderInfo";
