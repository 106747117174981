import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { TokenCountdownAlert } from "@components/TokenCountdownAlert/TokenCountdownAlert";
import { LANG, URL_CHECKOUT_TOKEN, URL_ORDER_TOKEN } from "@constants/queryKeys";
import { BOX_BACKGROUND_COLOR_DEFAULT_CONTRACT, STORAGE_DEFAULT_CONTRACT } from "@constants/sessionStorageDefaults";
import { BOX_BACKGROUND_COLOR, CONTRACT_DATA_KEY, MERCHANT_DATA_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import { webCheckoutPageMap } from "@modules/WebCheckout";
import { languages, languageService } from "@store/languageService";
import { setExpireTokenTimer } from "@utils/helpers";
import { useQuery } from "@utils/hooks/useQuery/useQuery";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const ContentContainer = styled(Grid)(({ theme }) => ({
  height: "100%",
  width: "100%",
  paddingRight: "0px",
  paddingLeft: "0px",
  justifyContent: "center",
  [theme.breakpoints.up("sm")]: {
    paddingTop: "24px",
    paddingRight: "64px",
    paddingLeft: "64px",
  },
}));

const ItemContainer = styled(Box)(({ theme }) => ({
  padding: "24px",
  borderRadius: "16px",
  // background: "white",
  boxSizing: "border-box",
  minHeight: "340px",
  [theme.breakpoints.up("sm")]: {
    padding: "40px",
  },
}));

interface IContentLayoutProps {
  mainContent: React.ReactElement;
  additionalContent: React.ReactElement | null;
}
// TODO: Thnik about more clear way of work with order info component
export const ContentLayout: React.FC<IContentLayoutProps> = ({ mainContent, additionalContent }) => {
  const query = useQuery();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const checkoutToken = query.get(URL_CHECKOUT_TOKEN) || "";
  const orderToken = query.get(URL_ORDER_TOKEN);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [checkoutTokenStorageValue, setCheckoutTokenToStorage] = useSessionStorageState(MERCHANT_DATA_KEY, {
    orderId: "",
    checkoutToken: checkoutToken || "",
    merchantUrl: "",
    merchantSuccessUrl: "",
    merchantFailureUrl: "",
    merchantCancelUrl: "",
  });
  const [orderData] = useSessionStorageState(CONTRACT_DATA_KEY, STORAGE_DEFAULT_CONTRACT);
  const [boxBackgroundColor, setBoxBackgroundColor] = useSessionStorageState(
    BOX_BACKGROUND_COLOR,
    BOX_BACKGROUND_COLOR_DEFAULT_CONTRACT,
  );
  const isOnWebCheckout = location.pathname.startsWith(webCheckoutPageMap.InitialStep.path);

  const hasAdditionalPart =
    !!(checkoutToken || checkoutTokenStorageValue.checkoutToken || orderToken || orderData.orderToken) &&
    !isSmallScreen &&
    !isOnWebCheckout;

  useEffect(() => {
    const needRedirect = process.env.REACT_APP_NEED_REDIRECTION !== "false";
    if (!checkoutTokenStorageValue.checkoutToken && needRedirect && !orderToken) {
      history.push(siteMap.Error404Page.path);
    }
    if (checkoutToken !== "") {
      setCheckoutTokenToStorage({
        ...checkoutTokenStorageValue,
        checkoutToken: checkoutToken,
      });
      setExpireTokenTimer(checkoutToken);
    }
    setBoxBackgroundColor({ withColor: true });
    if (query.get(LANG) === "ar_SA") languageService.setLanguage(languages.AR);
    else languageService.setLanguage(languages.EN);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await sleep(500);
      const expirationDate = sessionStorage.getItem("tokenExpire");
      const diffDates = new Date(parseInt(expirationDate || "0")).getTime() - new Date().getTime();
      if ((diffDates < 0 && expirationDate) || !expirationDate) {
        history.push(siteMap.TokenExpired.path);
        return;
      }
    };
    fetchData();
  }, [sessionStorage]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", flexGrow: 1 }}>
      <ContentContainer maxWidth="1440px" container>
        <Grid
          item
          xs={12}
          sm={12}
          md={hasAdditionalPart ? 8 : 12}
          display={hasAdditionalPart ? "" : "flex"}
          justifyContent="center"
          paddingLeft="0px"
        >
          <ItemContainer
            width="100%"
            maxWidth="872px"
            style={{ background: boxBackgroundColor.withColor ? "white" : "inherit" }}
          >
            <TokenCountdownAlert display={boxBackgroundColor.withColor} />
            {mainContent}
          </ItemContainer>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{ display: hasAdditionalPart ? "inherit" : "none" }}
          paddingLeft={theme.direction === "ltr" ? "40px" : "unset"}
          paddingRight={theme.direction === "rtl" ? "40px" : "unset"}
          width="auto"
          marginTop={!boxBackgroundColor.withColor ? 14 : 0}
        >
          <ItemContainer style={{ background: "white", maxHeight: "480px" }}>{additionalContent}</ItemContainer>
        </Grid>
      </ContentContainer>
    </Box>
  );
};
