import {
  Box,
  ButtonProps,
  DialogContent,
  DialogProps,
  Grid,
  Dialog as MUIDialog,
  styled,
  useTheme,
  IconButton,
} from "@mui/material";
import { Button, COLORS, CrossIcon, Typography } from "@ui-kit";
import { noop } from "rxjs";

export interface IDialogProps extends DialogProps {
  open: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  onAgree?: () => void;
  closeBtnTitle?: JSX.Element;
  title?: string;
  description?: JSX.Element;
  icon?: JSX.Element;
  agreeButtonText?: string;
  cancelButtonText?: string;
  isNotError?: boolean;
}

const StyledDialog = styled(MUIDialog)(({ theme }) => ({
  ".MuiBackdrop-root": {
    backgroundColor: "rgba(107, 114, 124, 0.24)",
  },
  "& .MuiPaper-root": {
    boxShadow: "0 12px 24px rgba(44, 41, 38, 0.02)",
    borderRadius: "16px",
    width: "100%",
    maxWidth: "560px",
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-container.MuiDialog-scrollPaper": {
      alignItems: "flex-end",
    },
    "& .MuiPaper-root": {
      margin: 0,
      maxWidth: "100vw",
      overflowX: "hidden",
      maxHeight: "60vh",
      borderBottomRightRadius: "0",
      borderBottomLeftRadius: "0",
    },
    "& .MuiDialogContent-root": {
      padding: "25px",
      minWidth: "unset",
    },
  },
}));

const DialogWrapper = styled(DialogContent)`
  padding: 40px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeadTitleText = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
`;

interface IStyledButton extends ButtonProps {
  withMarginRight?: boolean;
}

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "withMarginRight",
})<IStyledButton>(({ theme }) => ({
  minWidth: "156px",
  maxWidth: "327px",
  [theme.breakpoints.up("sm")]: {
    minWidth: "220px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    maxWidth: "unset",
  },
}));

export const BasicDialog: React.FC<IDialogProps> = ({
  open,
  onClose = noop,
  onCancel = noop,
  onAgree = noop,
  title,
  description,
  icon,
  isNotError = false,
  cancelButtonText,
  agreeButtonText,
  closeBtnTitle,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <StyledDialog open={open} onClose={onClose} {...rest}>
      <DialogWrapper>
        {onClose != noop || closeBtnTitle ? (
          <Box
            sx={{
              textAlign: "right",
              width: "100%",
              marginBottom: "10px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: onClose != noop ? "row-reverse" : "row",
            }}
          >
            {onClose != noop && (
              <IconButton
                sx={{ height: 30, width: 30, borderRadius: 20, backgroundColor: COLORS.GREY_04 }}
                onClick={onClose}
              >
                <CrossIcon height={20} width={20} />
              </IconButton>
            )}
            {closeBtnTitle && <HeadTitleText>{closeBtnTitle}</HeadTitleText>}
          </Box>
        ) : null}
        {!!icon && icon}
        <Box sx={{ marginBottom: "40px", marginTop: "20px", maxWidth: "480px" }}>
          {title && (
            <Typography
              variant={"p1"}
              display={"block"}
              textAlign={"center"}
              fontWeight={theme.direction === "ltr" ? "bold" : 600}
              color={isNotError ? COLORS.GREY_90 : COLORS.SECONDARY_DARK_RED}
            >
              {title}
            </Typography>
          )}
          {description && (
            <Box sx={{ textAlign: "center", marginTop: { xs: "8px", sm: "16px" }, whiteSpace: "pre-line" }}>
              {description}
            </Box>
          )}
        </Box>
        <Grid
          container
          width="100%"
          justifyContent="center"
          flexWrap="nowrap"
          gap="40px"
        >
          {cancelButtonText && (
            <Grid item sx={{ width: { sm: "unset", xs: "100%" } }}>
              <StyledButton
                variant="contained"
                onClick={onCancel}
                color="secondary"
                withMarginRight={!!agreeButtonText}
                sx={{ fontWeight: theme.direction === "ltr" ? "bold" : 600 }}
              >
                {cancelButtonText}
              </StyledButton>
            </Grid>
          )}
          {agreeButtonText && (
            <Grid item sx={{ width: { sm: "unset", xs: "100%" } }}>
              <StyledButton
                variant="contained"
                onClick={() => onAgree()}
                sx={{ fontWeight: theme.direction === "ltr" ? "bold" : 600 }}
              >
                {agreeButtonText}
              </StyledButton>
            </Grid>
          )}
        </Grid>
      </DialogWrapper>
    </StyledDialog>
  );
};
