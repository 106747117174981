export const TRANSLATIONS_EN = {
  hello: "Hello my friend",
  additionalPart: "Additional part",
  loading: "Loading...",
  error404Header: "Oops!",
  tokenMessage: "This offer will expire in next:",
  error404: "Error 404. Seems like page you're looking for doesn't exist.",
  units: {
    month: "month",
    month_one: "month",
    month_other: "months",
    trial: "Trial",
    trial_one: "Trial",
    trial_other: "Trials",
  },

  currency: {
    SAR: "SAR",
  },
  buttons: {
    logIn: "Log in",
    logInSingle: "Login",
    continue: "Continue",
    goBack: "Go back",
    resend: "Resend",
    ok: "Ok",
    exit: "Exit",
    tryAgain: "Try again",
    createAccount: "Create  Account",
    cancel: "Cancel",
    yesCancel: "Yes, cancel",
    no: "No",
    yes: "Yes",
    proceed: "Proceed",
    backToStore: "Back to store",
    goToStore: "Go to store",
    downloadApp: "Download Quara Finance app",
    continueHere: "Continue here",
    continueWithApp: "Continue with app",
    goToTheApp: "Go to the app",
    getStarted: "Get started",
    goToContract: "Go to the contract",
    reject: "Reject",
    accept: "Accept",
    back: "Back",
    sign: "Sign",
    updateDOB: "Update date of birth",
    callBack: "Request another call",
    selectSector: "Select sector",
    cancelDeletion: "Cancel deletion",
  },
  labels: {
    email: "E-mail address",
    mobile: "Mobile number",
    nationalIdOrIQAMA: "National ID or IQAMA",
    birthDate: "Date of Birth",
    passwordRequire: "Password should contain:",
    password: "Password",
    passwordRepeat: "Repeat password",
    recoveryCode: "Recovery code",
    retryIn: "Please retry in",
    passwordStrength: {
      Low: "Weak",
      Medium: "Medium",
      Great: "Great",
    },
    accommodationType: "Type of accommodation",
    chooseAccommodation: "Select Accommodation type",
    dependentsNumber: "Number of dependents",
    monthlyExpenses: "Monthly Expenses",
    comingSoon: "Coming soon",
    ownHouse: "Own house",
    rental: "Rental",
    setBirthday: "Set birthday",
    remaining: "Remaining",
    selectSector: "Select a sector",
    monthlyIncome: "Monthly Income",
    monthlyIncomeDescription: "Minimum monthly income must be SAR 4000",
    monthlyRent: "Monthly Rent",
    householdWage: "Household wage",
    utilityExpense: "Utility Expense",
    insurancePremium: "Insurance Premium",
    transportExpenses: "Transport Expenses",
    healthcareExpenses: "Healthcare Expenses",
    communicationExpenses: "Communication Expenses",
    foodAndLivingExpenses: "Food and Living Expenses",
    expatDependentGovFees: "Expat Dependent Gov. Fees",
    educationSuppliesExpense: "Education supplies expense",
    monthlyRemittance: "Monthly Remittance",
    monthlyRemittanceDescription: "(home country for family maintainance)",
    otherMonthlyExpenses: "Other monthly expenses",
    otherMonthlyExpensesDescription: "(including expected in the future)",
    numberOfDependants: "Number of dependants",
    publicSchoolDependants: "Number of dependants in public school",
    privateSchoolDependants: "Number of dependants in private school",
    numberOfHouseholdHelp: "Number of household help",
    numberOfAllDependants: "Number of all dependants",
    numberOfAllDependantsDescription:
      "(The number should include all the above dependants & those not mentioned there.)",
    resonsibleTitle: "I will be responsible for making payments",
    nameOfResponsiblePerson: "Name of person responsible for payments",
    enterNameOfResponsiblePerson: "Enter responsible person name",
    breadwinnerTitle: "Are you the sole breadwinner of the family?",
    rdef: "Are you using Real estate Development Fund?",
    homeOwnerTitle: "Are you a home owner?",
    politicalPersonTitle: "Are you a political person?",
    phoneNumberAbsher: "Please write the same number registered in Absher",
  },
  placeholders: {
    email: "Enter email",
    mobileNumber: "Enter mobile",
    nationalIdOrIQAMA: "Enter ID/Iqama",
    birthDate: "00/00/0000",
    password: "Password",
  },
  additionalInfo: {
    didntGetCode: "Didn't receive the Code?",
    forgotPassword: "Forgot password?",
  },
  errors: {
    EMAIL_NOT_VALID_ERROR: "Invalid e-mail address.",
    PHONE_NOT_VALID_ERROR: "Please write the same number registered in Absher.",
    NATIONAL_ID_NOT_VALID_ERROR: "Wrong Number. Please enter it correctly.",
    NATIONAL_ID_IS_MANDATORY_ERROR: "This field is mandatory",
    WRONG_PASSWORD_ERROR: "Password is incorrect. Please try again.",
    BIRTH_DATE_UNDERAGE_ERROR: "Invalid date of birth. The User of the App should be at least 21 years old.",
    UPPERCASE_one: "At least {{ numberOfSigns }} UPPERCASE letter",
    UPPERCASE_other: "At least {{ numberOfSigns }} uppercase letters",
    LOWERCASE_one: "At least {{ numberOfSigns }} lowercase letter",
    LOWERCASE_other: "At least {{ numberOfSigns }} lowercase letters",
    NUMBER: "At least {{ numberOfSigns }} number",
    SPECIAL_SYMBOL: 'At least {{ numberOfSigns }} special symbol: (~! @ # $% ^ & * _- + = \'| \\ () {} []: "" <>,.? /)',
    SYMBOLS: "At least {{ numberOfSigns }} signs",
    CODE_IS_INVALID: "Code is invalid, try again",
    OTP_ATTEMPTS_EXCEEDED24: "You have exceeded the maximum OTP attempts, Please wait 24 hours before next try.",
    OTP_ATTEMPTS_EXCEEDED: "You have exceeded the maximum security code attempts.",
    ACCOUNT_LOCKED_ATTEMPTS_EXCEEDED:
      "Your account is locked due to exceeding the maximum OTP attempts, Please contact Quara Finance support to unlock your account.",
    WRONG_OTP: "Wrong code.",
    LOGIN_ATTEMPTS_EXCEEDED: "You need to wait 5 minutes due to many failed attempts",
    PASSWORD_DONT_MATCH: "The password doesn't match. please try again.",
    PASSWORD_DONT_MATCH_LOWEST_CRITERIA: "Password does not meet the minimum criteria.",
    DATE_OF_BIRTH_IS_INCORRECT: "Please enter your date of birth correctly",
    Minmum_monthly_income: "Minimum monthly income must be SAR 4000",
  },
  pageHeader: {
    createAccount: {
      create: "Create New Account",
      account: "",
    },
    login: {
      login: "Login",
      account: "",
    },
    employmentSector: "Select Employment Sector",
    setupPassword: "Setup your password",
    codeVerification: "Enter Verification Code",
    forgotPassword: "Forgot password",
    verifyNewPassword: "Verfiy new password",
    employmentDetails: "Enter Financial details",
    offerDetails: "Our offer for your purchase",
    offerDetailsMobile: "Choose your program",
  },
  pageContent: {
    alreadyHaveAccount: "Already have an account?",
    dontHaveAccount: "Don’t have an account?",
    codeSentTo: "Please enter the code sent by Absher to your government-registered mobile phone",
    codeSentToNumber: "Please enter the security code that has been sent to",
    newPasswordCodeSentToNumber: "Please verify your new password via the code sent to your mobile number",
    didntReceiveCode: "Didn't receive the code?",
    resendCodeIn: "Resend code in",
    privateSector: "Private",
    governmentSector: "Government",
    newCodeSent: "New code has been sent.",
    verifyDataToResetPassword: "Verify your personal data to reset your password",
    offerDetails: {
      firstInstallment: "First installment",
      nextInstallment: "Next installments",
      lastInstallment: "Last installment",
      financeAmount: "Finance amount",
    },
    offerPayments: {
      chooseOffer: "choose your payment option",
      offerLabel: "Pay a fixed amount of SAR {{cost}} over {{month}} months",
      notEligibleForOffer: "Sorry you are not eligible for this program",
      fixedLabel: "Fixed",
      select3_6monthsLabel: "Select from 3-6 months to pay without any interest.",
      select3_6monthsTemporaryLabel: "Not available",
      comingSoon: "Coming soon",
      select3_6monthsHeader: "Pay in 3-6",
      payIn: "Pay in",
      noEligiblePrograms: "Unfortunately you are not eligible for any of our programs",
      contactToSupport: "Please contact us at: <0>{{email}}</0> for any support",
    },
  },
  dialogTitle: {
    threeDSecureVerification: "Your card will be verified using 3D Secure method",
    accountLocked: "Your account is locked",
    userAlreadyRegistered: "This user is already registered",
    userAlreadyRegistered_deprecated: "The user is already registered",
    unableToVerify: "Unfortunately we were unable to verify your identity",
    somethingWentWrong: "Something went wrong",
    incorrectData: "Incorrect Data Entry",
    privateSectorVerificationFailed: "Private sector verification failed",
    governmentSectorVerificationFailed: "Government sector verification failed",
    identityVerifying: "Unfortunately you can’t continue the process due to Compliance issues",
    success: "Success!",
    unableVerifyCardDetails: "Unfortunately, we were unable to confirm your card details",
    incorrectCardDetails: "Incorrect Card Deatils",
    balanceInsufficient: "Card Balance is insufficient",
    confirmCancelQuestion: "Are you sure you want to cancel?",
    loanOfferExpired: "Unfortunately, the loan offer has expired",
    couldNotProvideOffer: "Unfortunately we couldn’t provide you with a loan offer",
    couldNotProvideOfferPrivate: "Private sector verification failed",
    couldNotProvideOfferGovernment: "Government sector verification failed",
    didntMeetFinanceCreditCriteria: "Unfortunately you didn’t meet Quara Finance credit criteria",
    pleaseDownloadApp: "Please download the app",
    pleaseOpenOurApp: "Please open our app",
    rejectContract: "Are you sure you want to reject the contract ?",
    srsCheckHaveNotPassed: "Sorry, you didn`t meet the Quara Finance credit criteria",
    wrongDOB: "Please enter the correct date of birth according to your information",
    successfulCallbackRequest: "Request sent successfully",
    underDeletation: "This account is under cancellation process",
    finalLoanSammary: "Final Loan Summary",
  },
  dialogContent: {
    accountLocked: "To unlock your account, please contact us on <0>{{email}}</0>",
    proceedWithExistingLogin: "Please proceed to login with your account",
    checkProvidedData:
      "Please check your data again, it does not match those held by the government. You can also contact us on <0>{{ email }}</0>",
    requestWasntProcessed: "Due to unforeseen circumstances your request wasn’t processed. Please try again",
    dataDoesntExist: "Please Go back and try again",
    contactForMoreOptions: "If you would like to know more options, please contact us on  <0>{{ email }}</0>",
    unableProcessRequest: "We were unable to process your request. Please try again later.",
    tryAgain: "Please try again later",
    cardAddSuccess: "Your credit card has been added successfully!",
    unableDueInsufficientBalance:
      "We were unable to process your card payment due to an insufficient card balance. Please make sure you have sufficient credit on your card and try again.",
    cancelCardDetails: "If you want to cancel the card details press yes.",
    cancelOffer: "If you want to cancel the offer press yes.",
    offerExpired: "Please go back to the store and apply again to receive a new offer.",
    cardDetailsDoesntMatch:
      "The card detailes doesn’t match. Please make sure you have sufficient credit on your card and try again.",
    exitOrContact: "Please exit or contact us on <0>{{email}}</0>",
    youWillBeRedirectedToTheStore:
      "You will be redirected to the store in <0>{{timer}}</0>\n or click exit to go back now.",
    selectPlatform: "Select your platform type and go to the store to install our app and get your order",
    downloadFromStore: "Click on downloading to go to the store to install our app and get your order",
    confirmRejection: "If you want to reject the loan contract press yes.",
    srsCheckHaveNotPassed: "You will be redirected to the store in <0>{{timer}}</0> or click exit to go back now",
    srsCheckFailed: "Verification process is failed due to technical issues. Please try again",
    srsCheckFinallyFailed: "Verification process is failed due to technical issues. Please try again later",
    exceededRegAttempts: "You have exceeded the maximum trials for registering with this National ID/IQAMA",
    enterPhoneFromAbsher: "Please enter the same data as registered in Absher for a successful verification.",
    successfulCallbackRequest: "You’ll receive our call very soon",
    whereToGo: "Please decide where you want to go.",
    underDeletation:
      "You need to cancel the account deletion to continue applying for the finance. If you don’t cancel, loan application will stop.",
  },
  orderContent: {
    header: "Your purchase",
    subheader: {
      keyWithCount_one: "<0>{{ itemsAmount }} item</0> at a total value of <1> {{ sum }}</1>",
      keyWithCount_other: "<0>{{ itemsAmount }} items</0> at a total value of <1> {{ sum }}</1>",
    },
    productsInCart: "Products in cart:",
  },
  confirmationContent: {
    header: "Obligatory 24h waiting period.",
    whatWillHappenNext: "What happens next:",
    phoneCall: "You will receive a call from us to verify your application.",
    message: "A confirmation of approval will be sent via SMS.",
    contract: "With just a few clicks, sign the contract on the Quara Finance website.",
    nafithConfirm: "Confirm the Nafith e-promissory note.",
    transaction: "Congratulations! you will get the financing as soon as the 24h waiting period is over.",
    installMobileApp:
      "Sign your contract and manage your orders and installments later from the Quara Finance mobile application.",
    warnings: {
      contractIsWaitingForACall:
        "You already have a contract with Quara Finance. We will call you shortly to discuss having another contract. Please keep in mind, you can apply for another loan once the current one is fulfilled.",
      contractIsWaitingForASigning:
        "You already have an open contract. Please sign the contract thats is waiting for you in Quara Finance mobile application. You can apply for another loan after completing the formalities with the current one.",
      contractIsSigned:
        "You already have an open contract. Please wait for releasing current loan. You can check its current status in Quara Finance mobile application. You can apply for another loan after completing the formalities with the current one.",
    },
  },
  employmentDetails: {
    consent: "I give my consent to request my data from SIMAH Credit Bureau.",
    fullConsent: "Full consent",
    consentDialogTitle: "Consent for Information from SIMAH",
    consentDialogDescription:
      "I, the undersigned, (Agent/Authorized person (on behalf of the Business/Company)), hereby agree to provide Quara Finance Co. with any information required for the establishing and/or auditing and/or administering of my accounts and facilities. Additionally, I authorize Quara Finance Co. to obtain and collect any information it deems necessary regarding me, my accounts, and facilities therewith, from the Saudi Credit Bureau (SIMAH) and to disclose and share (inclusive of Data Pooling) that information to the said company.",
  },
  newOfferCalculator: {
    requestAmount: "The requested amount",
    description: "Select the most suitable financing offer",
    sarPerMonth: "SAR/MO",
  },
  offerDetails: {
    totalAmount: "Total amount",
    description: "Financing offer details",
    requested_amount: "Requested Amount",
    profit: "Profit",
    tenure: "Tenure",
    apr: "APR",
    monthly_payment: "Monthly Payment",
    interest_rate: "Interest Rate",
  },
};
