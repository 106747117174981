import { Alert, styled } from "@mui/material";
import { COLORS, Typography } from "@ui-kit";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { siteMap } from "@constants/siteMap";

const StyledAlert = styled(Alert)`
  background: ${COLORS.WHITE};
  border-left: 3px solid ${COLORS.PRIMARY_DARK};
  box-shadow: 0px 4px 24px rgb(0 0 0 / 8%);
  border-radius: 5px;
  margin: 30px auto;
  color: ${COLORS.GREY_90};
  font-size: 14px;
`;

const StyledCounter = styled(Typography)`
  color: ${COLORS.PRIMARY_DARK};
  display: inline-block;
  margin: 0 4px;
  font-size: 14px !important;
`;

interface IStartTokenCountdownAlert {
  display?: boolean;
}

interface Dates {
  hours?: number;
  minutes?: number;
  seconds?: number;
}

const addZeroBeforeNumber = (num = 0): string => {
  if (num < 10) return "0" + num;
  return String(num);
};

export const TokenCountdownAlert: React.FC<IStartTokenCountdownAlert> = ({ display = true }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [dates, setDates] = useState<Dates>({ hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const refreshIntervalId = setInterval(() => {
      const expirationDate = sessionStorage.getItem("tokenExpire");
      const diffDates = new Date(parseInt(expirationDate || "0")).getTime() - new Date().getTime();
      const h = Math.floor((diffDates % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const m = Math.floor((diffDates % (1000 * 60 * 60)) / (1000 * 60));
      const s = Math.floor((diffDates % (1000 * 60)) / 1000);

      if (diffDates < 0) {
        setDates({ ...dates, hours: 0, minutes: 0, seconds: 0 });
        history.push(siteMap.TokenExpired.path);
        clearInterval(refreshIntervalId);
        return;
      } else {
        setDates({ ...dates, hours: h, minutes: m, seconds: s });
      }
    }, 1000);
    return () => {
      clearInterval(refreshIntervalId);
    };
  }, [sessionStorage.getItem("tokenExpire")]);

  if (!display) return <></>;

  return (
    <StyledAlert icon={false}>
      {t("tokenMessage")}
      <StyledCounter>
        {addZeroBeforeNumber(dates.hours) +
          ":" +
          addZeroBeforeNumber(dates.minutes) +
          ":" +
          addZeroBeforeNumber(dates.seconds)}
      </StyledCounter>
    </StyledAlert>
  );
};
