import { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

import { ContentLayout } from "@components/ContentLayout";
import { LoaderProvider } from "@components/LoaderProvider";
import { OrderInfo } from "@components/OrderInfo";
import { TopMenu } from "@components/TopMenu";
import { BOX_BACKGROUND_COLOR_DEFAULT_CONTRACT } from "@constants/sessionStorageDefaults";
import { BOX_BACKGROUND_COLOR } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import { Error404Page } from "@pages/Error404Page";
import { UnderMaintenance } from "@pages/UnderMaintenance";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";

import { MainContentRouter } from "./MainContentRouter";

export const AppRouter: React.FC = () => {
  const [, setBoxBackgroundColor] = useSessionStorageState(BOX_BACKGROUND_COLOR, BOX_BACKGROUND_COLOR_DEFAULT_CONTRACT);
  const isUnderMaintenance = true;
  const history = useHistory();

  useEffect(() => {
    if (isUnderMaintenance) history.push(siteMap.HomePage.path);
    setBoxBackgroundColor({ withColor: true });
  }, []);
  return (
    <Switch>
      <Route path={siteMap.Error404Page.path}>
        <Error404Page />
      </Route>
      <Route path={siteMap.HomePage.path}>
        <LoaderProvider>
          <TopMenu />
          {isUnderMaintenance ? (
            <UnderMaintenance />
          ) : (
            <ContentLayout mainContent={<MainContentRouter />} additionalContent={<OrderInfo />} />
          )}
        </LoaderProvider>
      </Route>
    </Switch>
  );
};
