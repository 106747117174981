import { useLogout } from "@utils/network/useLogout";

export const logoutWithRedirect = async (redirectUrl: string): Promise<void> => {
  const [logoutUser] = useLogout();

  await logoutUser();
  await sessionStorage.clear();

  window.location.href = redirectUrl;
};
