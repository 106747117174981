import { FC, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { noop } from "rxjs";

import { COMMON_REQUEST_DIALOGS, IDialogWithHandler, IDialogComponentProps } from "@appTypes/dialogs";
import { BasicDialog } from "@components/Dialogs/BasicDialog";
import { MERCHANT_DATA_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import { CHECK_SRS_DIALOG_TYPES } from "@utils/hooks/useCheckSRS/useCheckSRS";
import { SAFETY_CHECK_DIALOG_TYPES } from "@utils/hooks/useCheckSRS/useSafetyCheck";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { logoutWithRedirect } from "@utils/services/Authentication/logoutWithRedirect";

import { employmentDialogTypes, EMPLOYMENT_DETAILS_DIALOGS } from "../EmploymentDetails/EmploymentDetails.types";
import { DialogContentSwitcher } from "./DialogContentSwitcher";

interface IUserDialogs extends IDialogComponentProps<employmentDialogTypes, void> {
  onReopenDialog: (dialogParams: IDialogWithHandler<employmentDialogTypes, void>) => void;
}

export const EmploymentDialogs: FC<IUserDialogs> = memo(
  ({ dialog, dialogType, isDialogOpen, closeDialog, lastAction = noop, parameters = [], onReopenDialog }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [merchantData] = useSessionStorageState(MERCHANT_DATA_KEY, {
      checkoutToken: "",
      merchantUrl: "",
      merchantSuccessUrl: "",
      merchantFailureUrl: "",
      merchantCancelUrl: "",
    });

    const redirectToMerchantWebsite = useCallback(async () => {
      closeDialog();
      await logoutWithRedirect(merchantData.merchantUrl);
    }, [merchantData.merchantUrl]);

    const handleAgreeDialogClick = useCallback(async () => {
      switch (dialogType) {
      case CHECK_SRS_DIALOG_TYPES.SRS_CHECK_HAVE_NOT_PASSED:
        redirectToMerchantWebsite();
        break;
      case EMPLOYMENT_DETAILS_DIALOGS.PRIVATE_SECTOR_NOT_VERIFIED:
      case EMPLOYMENT_DETAILS_DIALOGS.GOVERNMENT_SECTOR_NOT_VERIFIED:
        history.push(siteMap.EmploymentDetailsPage.path);
        closeDialog();
        break;

      case SAFETY_CHECK_DIALOG_TYPES.SAFETY_CHECK_DIALOG_REQUEST_FAILED:
      case CHECK_SRS_DIALOG_TYPES.SRS_CHECK_FAILED: {
        const params = await lastAction(...parameters);

        onReopenDialog(params);
        break;
      }

      case EMPLOYMENT_DETAILS_DIALOGS.CONSENT:
        closeDialog();
        break;

      case COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG:
      default: {
        closeDialog();
        lastAction(...parameters);
        break;
      }
      }
    }, [dialogType, lastAction]);

    const handleCancelDialogClick = useCallback(async () => {
      switch (dialogType) {
      case EMPLOYMENT_DETAILS_DIALOGS.PRIVATE_SECTOR_NOT_VERIFIED:
      case EMPLOYMENT_DETAILS_DIALOGS.GOVERNMENT_SECTOR_NOT_VERIFIED:
        redirectToMerchantWebsite();
        break;
      }
    }, [dialogType]);
    return (
      <>
        <BasicDialog
          open={isDialogOpen}
          onClose={closeDialog}
          onAgree={handleAgreeDialogClick}
          onCancel={handleCancelDialogClick}
          title={t(dialog?.title as string)}
          description={
            <DialogContentSwitcher
              dialogType={dialogType}
              description={dialog?.description}
              contentOptions={{ redirectToMerchantWebsite }}
            />
          }
          icon={dialog?.icon}
          cancelButtonText={dialog?.cancelText ? t(dialog.cancelText as string) : ""}
          agreeButtonText={dialog?.agreeText ? t(dialog.agreeText as string) : ""}
        />
      </>
    );
  },
);

EmploymentDialogs.displayName = "EmploymentDialogs";
