import { COMMON_REQUEST_DIALOGS, IDialogInfo } from "@appTypes/dialogs";
import * as httpCodes from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups";
import { IAuthorization } from "@store/authorization/authorization";
import { IAxiosResponseWithError, network } from "@utils/network/network";

import { LOGIN_DIALOGS } from "../login.types";

const BAD_REQUEST_MESSAGE = "Bad Request";

interface IUserLoginData {
  nationalId: string;
  password: string;
}

// IDialogOptions
export interface IRequestResult extends IDialogInfo<COMMON_REQUEST_DIALOGS | LOGIN_DIALOGS, { blockTimer?: string }> {
  isSuccess: boolean;
  token?: IAuthorization;
}

type loginUserType = {
  loginUser: (userData: IUserLoginData) => Promise<IRequestResult>;
};

export const loginUserRequest = async (userData: IUserLoginData): Promise<IAxiosResponseWithError<IAuthorization>> => {
  const requestData = {
    username: userData.nationalId,
    password: userData.password,
  };

  return await network.request<IAuthorization>("connect", "customer/login", {
    method: "POST",
    data: requestData,
  });
};

export const useLogin = (): loginUserType => {
  const loginUser = async (userLoginData: IUserLoginData): Promise<IRequestResult> => {
    const { data, status, error } = await loginUserRequest(userLoginData);

    switch (status) {
    case httpCodes.OK:
      return { isSuccess: true, token: data };

    case httpCodes.BAD_REQUEST:
      if (error?.error === BAD_REQUEST_MESSAGE) {
        return {
          isSuccess: false,
          dialogType: LOGIN_DIALOGS.USER_WRONG_CREEDS,
        };
      } else {
        return {
          isSuccess: false,
          dialogType: LOGIN_DIALOGS.USER_BLOCKED,
          dialog: dialogContents.accountLocked,
        };
      }

    case httpCodes.UNAUTHORIZED:
      return {
        isSuccess: false,
        dialogType: LOGIN_DIALOGS.USER_WRONG_CREEDS,
      };

    case httpCodes.FORBIDDEN:
      return {
        isSuccess: false,
        dialogType: LOGIN_DIALOGS.USER_LOGIN_ATTEMPTS_BLOCKED,
        dialogOptions: {
          blockTimer: error?.message,
        },
      };

    default:
      return {
        isSuccess: false,
        dialogType: COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG,
        dialog: dialogContents.somethingWentWrong,
      };
    }
  };

  return { loginUser };
};
