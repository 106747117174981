import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { BaseInput, Button, CustomDatePicker, PhoneNumberInput } from "@ui-kit";
import { FC, useEffect, useMemo, useState } from "react";
import arabicCalendar from "react-date-object/calendars/arabic";
import gregorianCalendar from "react-date-object/calendars/gregorian";
import { useTranslation } from "react-i18next";
import { DateObject } from "react-multi-date-picker";
import { useHistory } from "react-router-dom";

import { UserDataFormFields } from "@appTypes/userData";
import { ReactComponent as InfoIcon } from "@assets/icons/info_icon.svg";
import { StartPageHeader } from "@components/StartPageHeader/StartPageHeader";
import { TextWithLink } from "@components/TextWithLink/TextWithLink";
import { emptyUserData } from "@constants/authorization";
import { DEFAULT_USER_FORM_DATA, USER_FORM_FIELDS } from "@constants/defaults/userData";
import { REGISTRATION_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import { authorizationStore } from "@store/authorization";
import { menuService } from "@store/menu";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { convertArabicToDigit } from "@utils/services/Text/Text.service";
import { validateFormFields } from "@utils/services/Validation";
import { hasEmptyFields } from "@utils/services/Validation/validators";

import { FORM_FIELDS } from "../UpdateDateOfBirth/UpdateDateOfBirth.constants";
import { DEFAULT_FORM_ERRORS, DEFAULT_REGISTRATION_DATA } from "./SetUserData.constants";
import { FormErrors, IUserDialogOptions, userDataDialogTypes } from "./SetUserData.types";
import { registrationInfoValidators } from "./SetUserData.validators";
import { useVerifyUserData } from "./hooks/useDataValidation";
import { useVerifyPhone } from "./hooks/useMobileVerification";
import { usePopupHandlers } from "./hooks/usePopupHandlers";

const StyledContinueButton = styled(Button)(({ theme }) => ({
  fontWeight: theme.direction === "ltr" ? "bold" : 600,
  width: "100%",
  maxWidth: "376px",
  [theme.breakpoints.up("sm")]: {
    width: "270px",
  },
}));

export const SetUserData: FC = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const history = useHistory();

  const [errors, setFormErrors] = useState<FormErrors>(DEFAULT_FORM_ERRORS);
  const [storageValue, setDataToStorage] = useSessionStorageState(REGISTRATION_KEY, { ...DEFAULT_REGISTRATION_DATA });
  const [isHijiri, setHijiriFlag] = useState(false);
  const isHijriDefaultDate =
    storageValue.form[USER_FORM_FIELDS.BIRTH_DATE] &&
    +storageValue.form[USER_FORM_FIELDS.BIRTH_DATE].toString().split("-")[0] < 1800;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [formFields, setFormData] = useState<UserDataFormFields>({
    ...(storageValue?.form
      ? {
        ...DEFAULT_USER_FORM_DATA,
        ...storageValue?.form,
      }
      : DEFAULT_USER_FORM_DATA),
    [FORM_FIELDS.BIRTH_DATE]:
      storageValue?.form && storageValue.form[USER_FORM_FIELDS.BIRTH_DATE]
        ? new DateObject({
          calendar: isHijriDefaultDate ? arabicCalendar : gregorianCalendar,
          date: storageValue.form[USER_FORM_FIELDS.BIRTH_DATE],
        })
        : "",
  });
  const currentDate = useMemo(() => new Date(), []);
  const { openPopupWithParams } = usePopupHandlers();
  const { validateUser } = useVerifyUserData();
  const { validatePhone } = useVerifyPhone();

  const handleEmailChange = (event: React.ChangeEvent<{ value: string }>) => {
    setFormData((prevState) => ({ ...prevState, [USER_FORM_FIELDS.EMAIL]: event.target.value }));
  };
  const handlePhoneNumberChange = (event: React.ChangeEvent<{ value: string }>) => {
    const maxLength = event.target.value.startsWith("05") ? 10 : 9;

    if (event.target.value.length <= maxLength) {
      setFormData((prevState) => ({ ...prevState, [USER_FORM_FIELDS.MOBILE_PHONE]: event.target.value }));
    }
  };
  const handleNationalIdInputChange =
    (formKey: string, maxLength: number) => (event: React.ChangeEvent<{ value: string }>) => {
      if (event.target.value.length <= maxLength) {
        setFormData((prevState) => ({ ...prevState, [formKey]: event.target.value }));

        setHijiriFlag(event.target.value.startsWith("1"));
      }
    };
  const handleDateChange = (chosenDate: DateObject): void =>
    setFormData((prevState) => ({ ...prevState, [USER_FORM_FIELDS.BIRTH_DATE]: chosenDate }));

  const handleContinueClick = async () => {
    const { isFormValid, formErrors } = validateFormFields(formFields, registrationInfoValidators, currentDate);

    if (isFormValid) {
      const convertedDate = convertArabicToDigit((formFields.BIRTH_DATE as DateObject).format("YYYY-MM-DD"));
      const userData = {
        ...formFields,
        [USER_FORM_FIELDS.BIRTH_DATE]: convertedDate,
        [USER_FORM_FIELDS.EMAIL]: formFields.EMAIL.toLowerCase(),
      };

      await setDataToStorage({
        form: userData,
      });
      setFormErrors(formErrors as FormErrors);

      const validateDataReq = async (userFormData: UserDataFormFields) => {
        const { isSuccess, ...dialogData } = await validateUser(userFormData);

        if (isSuccess) {
          const validatePhoneReq = async () => {
            const { isSuccess: isPhoneValidationSuccess, ...phoneDialogData } = await validatePhone();

            if (isPhoneValidationSuccess) {
              history.push(siteMap.SetupKYCPage.path);
            } else {
              openPopupWithParams<userDataDialogTypes, IUserDialogOptions>({
                ...phoneDialogData,
                lastAction: validatePhoneReq,
              });
            }
          };
          validatePhoneReq();
        } else {
          openPopupWithParams({ ...dialogData, lastAction: validateDataReq, parameters: [userFormData] });
        }
      };

      validateDataReq(userData);
    } else {
      setFormErrors(formErrors as FormErrors);
    }
  };

  const isNextDisabled = hasEmptyFields(formFields);

  useEffect(() => {
    authorizationStore.setAuthorization(emptyUserData);
    setHijiriFlag(formFields.NATIONAL_ID.startsWith("1"));

    menuService.setMenuData({
      leftSide: "pageHeader.createAccount.create",
      rightSide: "pageHeader.createAccount.account",
      needIcon: true,
    });
  }, []);

  return (
    <>
      <Box>
        {!isMobile && (
          <StartPageHeader
            buttonText={t("buttons.logIn") as string}
            leftSideHeaderText={t("pageHeader.createAccount.create")}
            rightSideHeaderText={t("pageHeader.createAccount.account")}
            linkPathname={siteMap.LogInPage.path}
          />
        )}
        <Grid container flexDirection="column" rowSpacing={{ md: 3, lg: 3 }}>
          <Grid item container columnSpacing={5}>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              mb={{
                xs: errors[USER_FORM_FIELDS.EMAIL].length ? "16px" : "0px",
                sm: errors[USER_FORM_FIELDS.EMAIL].length ? "16px" : "0px",
              }}
            >
              <BaseInput
                value={formFields[USER_FORM_FIELDS.EMAIL]}
                onChange={handleEmailChange}
                label={t("labels.email")}
                labelId="text-input-with-error"
                placeholder={t("placeholders.email")}
                errorText={
                  errors[USER_FORM_FIELDS.EMAIL].length ? t(`errors.${errors[USER_FORM_FIELDS.EMAIL][0]}`) : ""
                }
              />
            </Grid>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              mb={{
                xs: errors[USER_FORM_FIELDS.MOBILE_PHONE].length ? "16px" : "0px",
                sm: errors[USER_FORM_FIELDS.MOBILE_PHONE].length ? "16px" : "0px",
              }}
            >
              <PhoneNumberInput
                value={formFields[USER_FORM_FIELDS.MOBILE_PHONE]}
                errorText={
                  errors[USER_FORM_FIELDS.MOBILE_PHONE].length
                    ? t(`errors.${errors[USER_FORM_FIELDS.MOBILE_PHONE][0]}`)
                    : ""
                }
                onChange={handlePhoneNumberChange}
                label={t("labels.mobile")}
                type="text"
                labelId="phone-label-error"
                inputProps={{ maxLength: 10 }}
                placeholder={t("placeholders.mobileNumber")}
                hintText={
                  <Grid display="flex" alignItems="center" paddingBottom={2}>
                    <InfoIcon />
                    <Grid marginX={1}>{t("labels.phoneNumberAbsher")}</Grid>
                  </Grid>
                }
              />
            </Grid>
          </Grid>
          <Grid item container columnSpacing={5}>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              mb={{
                xs: errors[USER_FORM_FIELDS.NATIONAL_ID].length ? "16px" : "0px",
                sm: errors[USER_FORM_FIELDS.NATIONAL_ID].length ? "16px" : "0px",
              }}
            >
              <BaseInput
                onChange={handleNationalIdInputChange(USER_FORM_FIELDS.NATIONAL_ID, 10)}
                value={formFields[USER_FORM_FIELDS.NATIONAL_ID]}
                errorText={
                  errors[USER_FORM_FIELDS.NATIONAL_ID].length
                    ? t(`errors.${errors[USER_FORM_FIELDS.NATIONAL_ID][0]}`)
                    : ""
                }
                type="text"
                fullWidth
                label={t("labels.nationalIdOrIQAMA")}
                labelId="national-id"
                placeholder={t("placeholders.nationalIdOrIQAMA")}
                allowNumbersOnly={true}
              />
            </Grid>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              mb={{
                xs: errors[USER_FORM_FIELDS.BIRTH_DATE].length ? "16px" : "0px",
                sm: errors[USER_FORM_FIELDS.BIRTH_DATE].length ? "16px" : "0px",
              }}
            >
              <CustomDatePicker
                lang={i18n.language === "ar" ? "ar" : "en"}
                dateValue={formFields[USER_FORM_FIELDS.BIRTH_DATE] as DateObject}
                onChange={handleDateChange}
                dateFormat={isHijiri ? "YYYY/M/D" : "D/MM/YYYY"}
                isHijri={isHijiri}
                placeholder={t("placeholders.birthDate")}
                calendarTitle={t("labels.setBirthday")}
                inputProps={{
                  label: t("labels.birthDate"),
                  labelId: "date-picker-input",
                  errorText: errors[USER_FORM_FIELDS.BIRTH_DATE].length
                    ? t(`errors.${errors[USER_FORM_FIELDS.BIRTH_DATE][0]}`)
                    : "",
                  showAdditionalMessage: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <StyledContinueButton onClick={handleContinueClick} disabled={isNextDisabled} variant="contained">
              {t("buttons.continue") as string}
            </StyledContinueButton>
            {isMobile ? (
              <TextWithLink
                text={t("pageContent.alreadyHaveAccount")}
                linkPath={siteMap.LogInPage.path}
                linkText={t("buttons.logInSingle")}
              />
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
